import { createAlert, createSpinner } from '../../common/functions';
import Popup from './popup_fn';

export default function initPopupSub() {
    console.log('init popup_subscribe.js');

    const popupSub = new Popup('#popup-subscribe', '.btn-popup-subscribe');
    let debounceSubmit;

    if(popupSub.btnsShow.length) {
        popupSub.btnsShow.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();

                if (!popupSub.popup) {
                    const ajax = $.ajax({
                        type: 'POST',
                        url: '/local/include/popup-subscribe.php',
                        processData: false,
                        contentType: false,
                    });

                    ajax.done((res) => {
                        const result = JSON.parse(res);
                        const footer = document.querySelector('footer');

                        footer.insertAdjacentHTML('afterend', result.html);

                        setTimeout(() => {
                            popupSub.popup = document.querySelector('#popup-subscribe');
                            popupSub.btnClose = popupSub.popup.querySelector('.popup-close');

                            setJs();

                            popupSub.open();
                        })
                    });
                } else {
                    popupSub.open();
                }
            })
        })
    }

    function setJs() {
        const popupSubForm = document.querySelector('#popup-subscribe #sender');
        const popupCloseBtn = popupSub.popup.querySelector('.popup-close');

        if (popupSub.btnsShow[0]) {
            popupSub.btnsShow[0].addEventListener('click', (e) => {
                e.preventDefault();
                popupSub.open();
            });
        }
    
        if (popupSubForm) {
            popupSubForm.addEventListener('submit', (e) => {
                e.preventDefault();

                clearTimeout(debounceSubmit);

                debounceSubmit = setTimeout(() => {
                    popupSubForm.disabled = true;

                    const btnSubmit = popupSubForm.querySelector('[type="submit"]');
                    let btnSubmitText;

                    if(btnSubmit) {
                        btnSubmitText = btnSubmit.textContent;
                        btnSubmit.innerHTML = '';
                        btnSubmit.append(createSpinner());
                        btnSubmit.style.pointerEvents = 'none';
                    };

                    const ajax = $.ajax({
                        url: popupSubForm.getAttribute('action') + '?action=subscribe',
                        data: $(popupSubForm).serialize(),
                        type: 'POST',
                        dataType: 'json',
                    });

                    ajax.done(function (result) {
                        let footer = document.querySelector('footer');

                        if(btnSubmit) {
                            btnSubmit.innerHTML = btnSubmitText;
                            btnSubmit.style.pointerEvents = 'auto';
                        }

                        if(result.final) {
                            let alert = document.querySelector('.popup-alert-js');

                            popupSub.close();

                            if(!alert) {
                                alert = createAlert(result);
                                footer.insertAdjacentElement('afterend', alert);
                            } else {
                                alert.querySelector('.popup-content .frm-row').innerHTML = result.html;
                            }

                            alert.classList.add('active');

                            if(typeof ym !== 'undefined') {
                                ym(50245704, 'reachGoal', 'podpiska');
                            }
                        } else {
                            let parser = new DOMParser();
                            let html = parser.parseFromString(result.html, 'text/html');
                            let popupInner = html.querySelector('.popup-inner');

                            popupSub.popup.innerHTML = '';
                            popupSub.popup.insertAdjacentElement('afterbegin', popupInner);
                            setJs();
                        };

                        popupSubForm.disabled = false;
                    });
                }, 200);
            });
        }
    
        popupCloseBtn.addEventListener('click', () => popupSub.close());

        // check politic
        const checkbox = document.querySelector('#sender #rd03');
        const politicBlock = popupSubForm.querySelector('.politic');

        if(checkbox && politicBlock) {
            checkbox.addEventListener('change', () => {
                if(checkbox.checked) {
                    politicBlock.style.display = "none";
                } else {
                    politicBlock.style.display = "block";
                    politicBlock.querySelector('.frm-error').textContent = 'Для продолжения примите условия соглашения';
                }
            })
        }
    }
}
