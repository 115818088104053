import { setCatalogItemValues, setTabSlider } from '../../common/functions';

export default function ajaxSliders() {
    console.log('init ajaxSliders.js');

    // load ajax sliders
    const slider = {
        new: {
            init: false,
            plug: document.querySelector('.catalog-slider__new'),
            get offset() {
                return !!this.plug && this.plug.getBoundingClientRect().top
            }
        },
        sale: {
            init: false,
            plug: document.querySelector('.catalog-slider__sale'),
            get offset() {
                return !!this.plug && this.plug.getBoundingClientRect().top
            }
        },
        hit: {
            init: false,
            plug: document.querySelector('.catalog-slider__hit'),
            get offset() {
                return !!this.plug && this.plug.getBoundingClientRect().top
            }
        }
    }

    updateOffset();

    window.addEventListener('scroll', sendAjaxSliders);

    function sendAjaxSliders() {
        sendAjaxSlider(slider.new);
        sendAjaxSlider(slider.sale);
        sendAjaxSlider(slider.hit);
    }

    function sendAjaxSlider(sliderObj) {
        let windowScrollVal = window.pageYOffset + window.screen.height;

        if(
            sliderObj.plug && 
            !sliderObj.init && 
            windowScrollVal > sliderObj.offsetTop
        ) {
            getSliderAjax(sliderObj.plug, sliderObj.plug.dataset.ajaxUrl, sliderObj.plug.dataset.mobile);
            sliderObj.init = true;
        }
    }

    function getSliderAjax(wrapper, url, mobile = false) {
        let ajax = $.ajax({
            type: 'POST',
            url: url,
        });

        ajax.done(function (response) {
            async function fn() {
                wrapper.innerHTML = response;

                const title = wrapper.querySelector('h2');
                setCatalogItemValues(wrapper, title ? `Слайдер ${title.textContent}` : undefined);
            }

            fn().then(function () {
                mobile = parseInt(mobile);

                if (!mobile) {
                    console.log(`init ${url}`);

                    const catalogSlider = wrapper.querySelector(
                        '.catalog-slider__content-inner'
                    );
                    
                    if (!catalogSlider) return;

                    setTabSlider({
                        slider: catalogSlider,
                        count: 4,
                        classParent: '.catalog-slider__content-wrapper',
                        classPrev: '.catalog-slider__arrow-prev',
                        classNext: '.catalog-slider__arrow-next',
                        space: 35,
                    }).init();

                    // sendGdeslon(catalogSlider);
                }
            });

            updateOffset();
        });
    }

    function updateOffset() {
        for (const key in slider) {
            slider[key].offsetTop = slider[key].offset;
        }

        sendAjaxSliders();
    }

    function sendGdeslon(slider) {
        const isMainPage = document.querySelector('.page__inner-main');

        if(!isMainPage) return;

        const items = slider.querySelectorAll('[data-elementid]');

        if(!items.length) return;

        const arr = [];

        items.forEach(item => {
            const obj = {};
            let priceFiz = item.querySelector('.cat-price-info b');

            priceFiz = priceFiz 
                ? priceFiz
                : item.querySelector('.cat-price b');

            obj.id = item.dataset.elementid;
            obj.price = parseFloat(priceFiz.textContent.replace(' ', ''));
            obj.quantity = 1;

            arr.push(obj);
        });

        window.gdeslon_q = window.gdeslon_q || [];
        window.gdeslon_q.push({
            page_type: "main",
            merchant_id: "96431",
            order_id: "",
            category_id: "",
            products: arr,
            deduplication: ""
        });
    }
}
