export default function addToFavoriteItem() {
    console.log('init add_to_favorite.js');

    let timeoutAddToFolder;

    // добавление в папку
    $('body').on('click', '.modal_fav_folder >li >a', function (e) {
        var _this = $(this);

        clearTimeout(timeoutAddToFolder);

        timeoutAddToFolder = setTimeout(function () {
            _this.css('pointer-events', 'none');
            var folder_name = _this.data('name');
            var folder_key = _this.data('key');
            // var folder_name = $(this).data('name');
            // var folder_key = $(this).data('key');
            var action = 'add_to_fav_list';
            // var id = $(this).closest('.item-catalog').data('elementid');
            // var id = $(this).closest('.modal_fav_folder').data('id');
            var id = e.target.closest('.modal_fav_folder').getAttribute('data-id');
            const poduct = document.querySelector(`[data-elementid="${id}"]`);
            let productIco = false;

            if (poduct) {
                productIco = poduct.querySelector('.btn-cat-clip');
            }

            const hrefText = _this.children('.herf__main-text').text();
            const spinnerHtml = `
                <div class="fav_folders_wrapper" style="padding: 2px 5px;">
                    <div class="btn-action-cart-spinner">
                        <span class="circle"></span>
                        <span class="circle"></span>
                        <span class="circle"></span>
                        <span class="circle"></span>
                    </div>
                </div>
            `;

            _this.children('.herf__main-text').html(spinnerHtml);

            $.ajax({
                type: 'POST',
                url: '/local/ajax/favorite_folder.php',
                data: {
                    action: action,
                    data: folder_name,
                    data_key: folder_key,
                    id: id,
                },
                success: function (res) {
                    _this.children('.herf__main-text').html(hrefText);
                    var checked = $(
                        '.modal_fav_folder a[data-key="' + folder_key + '"]'
                    );
                    let count_items = checked
                        .find('span')
                        .eq(1)
                        .text()
                        .slice(1, -1);
                    let checkId = [];
                    let newDataIds = '';

                    if (res == 'del') {
                        checked
                            .next()
                            .children('.sidebar-dropdown__button-text')
                            .text('Удален')
                            .fadeIn(300);
                        checked.closest('li').attr('title', 'Добавить в папку');
                        checked
                            .find('span')
                            .eq(1)
                            .text('(' + --count_items + ')');

                        setTimeout(function () {
                            checked
                                .next()
                                .children('.sidebar-dropdown__button-text')
                                .fadeOut(300);
                        }, 2000);

                        if ($('#fav_folders').length) {
                            let fav_folder_name = $('#fav_folders').find(
                                "a[data-name='" + folder_name + "']"
                            );
                            let dataIds = fav_folder_name.data('ids');

                            fav_folder_name
                                .find('span')
                                .eq(1)
                                .text('(' + count_items + ')');

                            dataIds.forEach((element, index) => {
                                if (element === String(id)) {
                                    newDataIds = dataIds.splice(index, 1);
                                }
                            });

                            fav_folder_name[0].dataset.ids = dataIds;
                            _this[0].dataset.ids = dataIds;

                            const favFolderItems = $('#fav_folders').find(
                                '.sidebar__dropdown-href'
                            );

                            favFolderItems.each(function () {
                                const thisItem = $(this);
                                const thisItemIds = thisItem.data('ids');
                                let thisCheckId = true;

                                thisItemIds.forEach((element, index) => {
                                    if (element === String(id)) {
                                        thisCheckId = false;
                                    }
                                });

                                checkId.push(thisCheckId);
                            });

                            checkId = checkId.every((el) => el === true);

                            if (productIco && checkId) {
                                productIco.classList.remove('active');
                            }
                        }
                    } else if (res == 'add') {
                        // checked.next().text('товар добавлен в папку').stop(true).fadeIn();
                        checked
                            .next()
                            .children('.sidebar-dropdown__button-text')
                            .text('Добавлен')
                            .fadeIn(300);
                        checked.closest('li').attr('title', 'Удалить из папки');
                        checked
                            .find('span')
                            .eq(1)
                            .text('(' + ++count_items + ')');

                        setTimeout(function () {
                            checked
                                .next()
                                .children('.sidebar-dropdown__button-text')
                                .fadeOut(300);
                        }, 2000);

                        if ($('#fav_folders').length) {
                            let fav_folder_name = $('#fav_folders').find(
                                "a[data-name='" + folder_name + "']"
                            );
                            let dataIds = fav_folder_name.data('ids');

                            fav_folder_name
                                .find('span')
                                .eq(1)
                                .text('(' + count_items + ')');

                            dataIds.push(String(id));

                            dataIds.forEach((element, index) => {
                                newDataIds +=
                                    index !== dataIds.length - 1
                                        ? `"${element}",`
                                        : `"${element}"`;
                            });

                            fav_folder_name[0].dataset.ids = `[${dataIds}]`;
                            _this[0].dataset.ids = `[${dataIds}]`;

                            if (productIco) {
                                productIco.classList.add('active');
                            }
                        }
                    }
                    // if(checked.next().length){
                    //     checked.next().stop().fadeOut();
                    // }
                    _this.parent().toggleClass('active');
                    _this.css('pointer-events', 'auto');

                    // setTimeout(function(){
                    //     checked.next().stop(true).fadeOut();
                    // }, 1500);

                    // console.log(folder_key);
                    // console.log($('.modal_fav_folder a[data-key="'+ folder_key +'"]'));

                    // console.log(this);
                    // document.location.reload(true);
                },
            });
        }, 300);
    });

    document.body.addEventListener('click', (e) => {
        if (e.target.classList.contains('btn-cat-favorite')) {
            e.preventDefault();

            const itemBtnFavorite = e.target;
            const itemId = e.target.dataset.id;
            const btnClip = document.querySelector(`[data-elementid="${itemId}"] .btn-cat-clip`);

            if (itemId && itemId.length) {
                const ajax = $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    data: 'id=' + itemId,
                    url: '/local/ajax/favorite.php',
                });

                ajax.done(function (res) {
                    if (!res.success) return;

                    const iconDetailtText = itemBtnFavorite.querySelector('.product__controls-text');
                    const iconWrapper = itemBtnFavorite.parentElement;

                    if(!itemBtnFavorite.classList.contains('active')) {
                        itemBtnFavorite.classList.add('active');
                        if (iconWrapper.classList.contains('sidebar__dropdown-item')) {
                            iconWrapper.classList.add('active');
                        }
                        if (btnClip !== null) {
                            btnClip.classList.add('active');
                        }
                        if(iconDetailtText) iconDetailtText.textContent = 'В избранном'
                    } else {
                        itemBtnFavorite.classList.remove('active');

                        if (iconWrapper.classList.contains('sidebar__dropdown-item')) {
                            iconWrapper.classList.remove('active');
                        }

                        if (btnClip !== null) {
                            btnClip.classList.remove('active');
                        }

                        if(iconDetailtText) iconDetailtText.textContent = 'В избранное'

                        if(location.pathname.includes('/personal/favorite/')) {
                            const product = itemBtnFavorite.closest('[data-elementid]');

                            if(product) {
                                product.remove();
                            }
                        }
                    }

                    $('#favorinfo, .favorite-val, .sidebar__group-item--favorite .group-item__notify').text(res.favorinfo);
                    $('#favorinfo, .favorite-val, .sidebar__group-item--favorite .group-item__notify').css(
                        'display',
                        parseInt(res.favorinfo) >= 1 ? 'flex' : 'none'
                    );

                    $('.count_fav').each(function (i) {
                        $(this).text(res.favorinfo);
                    });

                    // favorite folders count
                    const favWrap = document.querySelector('.sidebar__group-item--favorite');

                    if(!favWrap) return;

                    res.fav_foldres.forEach(obj => {
                        const el = favWrap.querySelector(`[data-name="${obj.name}"]`);

                        if(!el) return;

                        const { ids } = obj;
                        const elVal = el.querySelector('.herf__main-val');

                        elVal.textContent = `(${ids.length})`;
                        el.dataset.ids = JSON.stringify(ids);
                    })
                });
            }

            showFavoriteFolders(itemBtnFavorite, itemId);
        }

        if (e.target.classList.contains('popup-close')) {
            let popupFavorite = document.querySelector('.popup-favorite');
            let popupFavoriteList = document.querySelector(
                '.popup-favorite .modal_fav_folder'
            );

            popupFavorite.classList.remove('active');

            setTimeout(() => {
                popupFavoriteList.innerHTML = `
                    <li class="fav_folders_wrapper" style="padding: 15px;">
                        <div class="btn-action-cart-spinner">
                            <span class="circle"></span>
                            <span class="circle"></span>
                            <span class="circle"></span>
                            <span class="circle"></span>
                        </div>
                    </li>
                `;
            }, 300);
        }
    });

    // вывод мод.окна выбора папки
    function showFavoriteFolders(btn, id) {
        if (
            btn.classList.contains('active') ||
            typeof window.foldersResponsive === 'undefined' ||
            !window.foldersResponsive ||
            window.foldersResponsive.length < 1
        )
            return;

        let popupFavorite = document.querySelector('.popup-favorite');
        let popupFavoriteList = document.querySelector(
            '.popup-favorite .modal_fav_folder'
        );

        if (popupFavorite && popupFavoriteList) {
            popupFavoriteList.dataset.id = id;

            const result = show_folders(popupFavoriteList, id);

            result.then(() => {
                popupFavorite.classList.add('active');
            })
        }
    }

    /**
     * folder_name - элемент, внутри которого выводим папки
     * current_id - текущий элемент id товара, для указания активной папки избранного
     * Если аргументов нет - передаем массив папок избранного
     */
    function show_folders(folder_container, current_id) {
        return new Promise(function (resolve) {
            let html;

            const ajax = $.ajax({
                type: 'POST',
                url: '/local/ajax/favorite_folder.php',
                dataType: 'json',
                data: {
                    action: 'show_folders',
                },
            });

            ajax.done(function (res) {
                if (!folder_container) return resolve(res);

                var fav = $.urlParam('fav');

                html = '';
                folder_container.innerHTML = html;

                if (res.length) {
                    res.forEach((value, key) => {
                        var current = key == fav ? 'current' : '';

                        var active = '';
                        current_id = String(current_id);
                        var value_ids = value.ids;
                        var is_current_folder = $.inArray(
                            current_id,
                            value_ids
                        );
                        // console.log(current_id);
                        // console.log(value_ids);
                        var status = '';
                        if (is_current_folder > -1) {
                            active = 'active';
                            // status = 'товар добавлен в папку';
                        }

                        let count_txt = '';
                        let value_id_count = value_ids.length;
                        if (value_id_count < 1) {
                            value_id_count = 0;
                            count_txt = ' _empty';
                        }

                        // console.log( active + current);
                        var hover = 'Добавить в папку';
                        if (current) {
                            var hover = 'Удалить из папки';
                        }

                        html += `
                            <li class="sidebar__dropdown-item ${active} ${current} ${count_txt}" title="${hover}">
                                <a class="sidebar__dropdown-href" data-ids="${JSON.stringify(
                                    value_ids
                                ).replaceAll('"','&apos;')}" data-key="${key}" data-name="${value.name}">
                                    <svg class="sidebar-dropdown__item-ico">
                                        <use xlink:href="/local/templates/main/components/bitrix/sale.personal.section/new/img/sprite.svg#clip"></use>
                                    </svg>
                                    <span class="herf__main-text">${
                                        value.name
                                    }</span>
                                    <span>(${value_id_count})</span>
                                </a>
                                <div class="sidebar-dropdown__button-group ">
                                    <span class="sidebar-dropdown__button-text" style="display: none;">Добавлен</span>
                                    <svg class="sidebar-dropdown__item-ico sidebar-dropdown__item-ico--secound">
                                        <use xlink:href="/local/templates/main/components/bitrix/sale.personal.section/new/img/sprite.svg#close"></use>
                                    </svg>
                                </div>
                            </li>
                            `;
                    });

                    folder_container.innerHTML = html;
                    return resolve(res);
                }
            });
        });
    }
}
