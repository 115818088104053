export default class Popup {
    constructor(popupSelecor, btnShowSelector, btnHideSlector) {
        this.popup = document.querySelector(popupSelecor);
        this.btnsShow = document.querySelectorAll(btnShowSelector);
        this.btnClose = document.querySelector(`${popupSelecor} ${btnHideSlector}`); 
    }

    open(openAddFn = false) {
        if(this.popup) {
            this.popup.classList.add('active');
        }

        if(this.btnShow) {
            this.btnShow.classList.add('active');
        }

        if(openAddFn) {
            openAddFn()
        }
    }

    close(closeAddFn = false) {
        if(this.popup) {
            this.popup.classList.remove('active');
        }

        if(this.btnShow) {
            this.btnShow.classList.remove('active');
        }

        if(closeAddFn) {
            closeAddFn()
        }
    }
}