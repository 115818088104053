import { createAlert, createSpinner, flagMaskPhone } from '../../common/functions';
import Popup from './popup_fn';

export default function initPopupMain() {
    console.log('init popup_main.js');

    const popupMain = new Popup(
        '#popup-main',
        '.btn-popup-message',
        '.popup-close'
    );
    let debounceSubmit;

    if(window.getCookie('popupMain') === '1') {
        const btn = document.querySelector('.btn-popup-message[href="#feebackform"]');

        if(btn) {
            initPopup(btn);
        }
    }

    if (popupMain.btnsShow.length) {
        popupMain.btnsShow.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                initPopup(btn);
            });
        });
    }

    function initPopup(btn) {
        if (!popupMain.popup) {
            const ajax = $.ajax({
                type: 'POST',
                url: '/local/include/popup-main.php',
                processData: false,
                contentType: false,
            });

            ajax.done((res) => {
                async function fn() {
                    const footer = document.querySelector('footer');

                    footer.insertAdjacentHTML('afterend', res);
                    popupMain.popup = document.querySelector('#popup-main');
                    popupMain.btnClose = popupMain.popup.querySelector('.popup-close');

                    popupMain.popup.addEventListener('click', e => {
                        const { target } = e;

                        if(
                            target instanceof HTMLElement &&
                            (
                                target.classList.contains('popup-bg') ||
                                target.classList.contains('popup-close')
                            )
                        ) {
                            e.preventDefault();
                            popupMain.close();
                            document.body.style.marginRight = 'unset';
                            document.querySelector('.header').style.marginRight = 'unset';
                            document.body.style.overflow = 'auto';
                        }
                    })

                    setJs(btn);
                }
                
                fn().then(function() {
                    if(
                        !btn.dataset.cityCall || 
                        !btn.dataset.cityCall.trim().length
                    ) return;

                    window.getCallLK(btn);
                })
            });
        } else {
            window.setActiveTab(btn);
        }
    }

    function setJs(btn) {
        if (!popupMain.popup) return;

        const tabsBlock = popupMain.popup.querySelectorAll('.tab-block');
        const tabsBtn = popupMain.popup.querySelectorAll('.tab-button');
        const inputFiles =
            popupMain.popup.querySelectorAll('input[type="file"]');
        const forms = popupMain.popup.querySelectorAll('form');

        if (tabsBtn.length && tabsBlock.length) {
            tabsBtn.forEach((tab) => {
                tab.addEventListener('click', (e) => {
                    if (!tab.dataset.tab) {
                        popupMain.close();
                    } else {
                        e.preventDefault();
                        tabChange(tab.dataset.tab);
                    }
                });
            });
        }

        if (inputFiles.length) {
            inputFiles.forEach((input) => {
                input.addEventListener('change', (e) => {
                    let wrapper = input.closest('.frm-field');

                    if (!wrapper) return;

                    let fileName = wrapper.querySelector('.frm-file__name');

                    if (input.files.length) {
                        fileName.innerHTML = input.files[0]['name'];
                        fileName.classList.add('active');
                    } else {
                        fileName.innerHTML = 'Файл не выбран';
                        fileName.classList.remove('active');
                    }
                });
            });
        }

        if (forms.length) {
            forms.forEach((form) => {
                $(form).validate({
                    rules: {
                        'popup-main__name': {
                            required: true,
                            minlength: 2
                        },
                        'popup-main__email': {
                            required: true,
                            email: true,
                            minlength: 2
                        },
                        'popup-main__phone': {
                            required: true,
                        },
                        'popup-main__select-sity': {
                            required: true,
                        },
                        'popup-main__select-user': {
                            required: true,
                        },
                        'popup-main__order-num': {
                            required: true,
                        },
                        check_politics: {
                            required : true
                        }
                    },
                    messages: {
                        form_text_8: {
                            required: "Заполните поле!",
                            minlength: "Введите не менее 2-х символов"
                        },
                        form_email_10: {
                            required: "Заполните поле!",
                            email: "Необходим формат адреса email",
                        },
                        form_dropdown_SELECT_CITY: {
                            required: "Поле обязательно для заполнения",
                        },
                        form_dropdown_SIMPLE_USER_TYPE: {
                            required: "Поле обязательно для заполнения",
                        },
                        form_text_211: {
                            required: "Поле обязательно для заполнения",
                        },
                        form_text_18: {
                            required: "Поле обязательно для заполнения",
                        },
                        form_text_20: {
                            required: "Поле обязательно для заполнения",
                        },
                        check_politics: {
                            required: "Для продолжения примите условия соглашения"
                        }
                    }
                });

                if(form.name='TAB_FORM_1') {
                    // visibility order number field
                    $("#tabpopup01 input[name='form_radio_SIMPLE_TYPE_1']").change(function(){
                        let radioType = $(this).attr("data-type");
                        let order_block = $("#tabpopup01 form").find('.form_order_num');
                        if(typeof radioType != "undefined" && radioType == "order"){
                            if(order_block.length > 0){
                                order_block.show();
                            }
                        } else {
                            if(order_block.length > 0){
                                order_block.hide();
                            }
                        }
                    });
                }

                form.addEventListener('submit', (e) => {
                    e.preventDefault();

                    clearTimeout(debounceSubmit);

                    debounceSubmit = setTimeout(() => {
                        form.disabled = true;

                        if(!$(form).valid()) return;

                        const dataTab = form.closest('[data-tab]').dataset.tab;
                        const btnSubmit = form.querySelector('button[type="submit"]');
                        const selectUser = form.querySelector('#SIMPLE_USER_TYPE');
                        const dynamicRespInp = createDinamicInp();
                        let data;
                        let btnSubmitText;

                        if(btnSubmit) {
                            btnSubmitText = btnSubmit.textContent;
                            btnSubmit.innerHTML = '';
                            btnSubmit.append(createSpinner());
                            btnSubmit.style.pointerEvents = 'none';
                        }

                        selectUser.removeAttribute('disabled');
                        form.insertAdjacentElement('afterbegin', dynamicRespInp);
                        data = new FormData(form);
                        selectUser.setAttribute('disabled', 'disabled');

                        const ajax = $.ajax({
                            type: 'POST',
                            data: data,
                            url: '/local/ajax/saveFormData.php',
                            processData: false,
                            contentType: false,
                        });

                        ajax.done((res) => {
                            const result = JSON.parse(res);
                            let alert = document.querySelector('.popup-alert-js');

                            if(result.result) {
                                dynamicRespInp.remove();
                                popupMain.close();

                                if(!alert) {
                                    const footer = document.querySelector('footer');

                                    alert = createAlert(result);
                                    footer.insertAdjacentElement('afterend', alert);
                                } else {
                                    alert.querySelector('.popup-content .frm-row').textContent = result.message;
                                }

                                alert.classList.add('active');
                                form.reset();

                                if(typeof ym !== 'undefined') {
                                    let ymTarget; 

                                    switch(dataTab) {
                                        case 'popup01':
                                            ymTarget = 'svyaz';
                                            break;
                                        case 'popup02':
                                            ymTarget = 'zvonok';
                                            break;
                                    }

                                    ym(50245704, 'reachGoal', ymTarget);
                                }
                            }

                            if(btnSubmit) {
                                btnSubmit.innerHTML = btnSubmitText;
                                btnSubmit.style.pointerEvents = 'auto';
                            }

                            form.disabled = false;
                        });
                    }, 200);

                });
            });
        }

        function createDinamicInp() {
            const input = document.createElement('input');

            input.name = 'dynamicResp';
            input.value = '1'
            input.style.display = 'none';

            return input;
        }

        // mask for inp tel
        const inpsPhone = $(popupMain.popup).find('input[name="form_text_20"], input[name="form_text_11"]');
        flagMaskPhone(inpsPhone);

        // $('#tabpopup02 input[type="text"],#tabpopup02 input[type="tel"]').on(
        //     'focus',
        //     function () {
        //         $(this).removeClass('inp-error').next().remove();
        //     }
        // );

        // $(
        //     '#tabpopup02 select[name="form_dropdown_SELECT_CITY"],#tabpopup01 select[name="form_dropdown_SELECT_CITY"], #tabpopup01 .form_order_num input, #tabpopup01 .form_user_type_fl_ul select, #tabpopup02 .form_user_type_fl_ul select'
        // ).on('focus', function () {
        //     $(this).removeClass('inp-error').next().remove();
        // });

        function tabChange(dataTab) {
            if (!tabsBlock.length || !tabsBtn.length) return;

            const activeTabBtn = popupMain.popup.querySelector(
                `.tab-button[data-tab="${dataTab}"]`
            );
            const activeTabBlock = popupMain.popup.querySelector(
                `.tab-block[data-tab="${dataTab}"]`
            );

            tabsBtn.forEach((tabItem) => tabItem.classList.remove('active'));
            tabsBlock.forEach((tabItem) => tabItem.classList.remove('active'));

            if (activeTabBtn && activeTabBlock) {
                activeTabBtn.classList.add('active');
                activeTabBlock.classList.add('active');
            }
        }

        window.setActiveTab = function (btn) {
            if (
                btn.dataset.tab === 'popup01' ||
                btn.dataset.tab === 'popup02'
            ) {
                tabChange(btn.dataset.tab);
            }

            popupMain.open();
            document.body.style.overflow = 'hidden';
            document.body.style.marginRight = '17px';
            document.querySelector('.header').style.marginRight = '17px';
            window.setCookie('popupMain', '0', '/')
        };

        window.setActiveTab(btn);
    }

    // $(document).ready(function() {

    //     $('#tabpopup02 form').submit(function (e) {
    //         var _self = $(this);
    //         var error_div = '<div class="error">Поле обязательно для заполнения</div>';
    //         var required_fields = ['form_text_18','form_text_20'];
    //         var has_error = false;

    //         $.each(required_fields, function (index, value) {
    //             var input_name = _self.find('input[name="'+ value +'"]');
    //             if(!input_name.val()){
    //                 has_error = true;
    //                 if(!input_name.next('div').length){
    //                     input_name.after(error_div);
    //                     input_name.addClass('inp-error');
    //                 }
    //             }
    //         });

    //         let option_name = _self.find('select[name="form_dropdown_SELECT_CITY"]');
    //         let option_value = option_name.children("option:selected").val();
    //         if(option_value == 193){
    //             has_error = true;
    //             if(!option_name.next('div').length){
    //                     option_name.after(error_div);
    //                     option_name.addClass('inp-error');
    //                 }
    //         }
    //         // console.log(option_value);

    //         if (has_error) {
    //             return false;
    //         } else{
    //             return true;
    //         }
    //     });
    // });
}
