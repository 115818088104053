import Popup from './popup_fn';

window.popupLogin = new Popup('#popup-login', 'header .btn-popup-login');
window.popupLoginSuccess = new Popup('#popup-login-success');

export function initPopupLogin() {
    console.log('init popup_login.js');

    const popupClose = document.querySelector('.popup-login__btn-close');
    const btnFogotPass = document.querySelectorAll('.fogot-pass-btn');

    for(var iter = 0; iter < btnFogotPass.length; iter++){
        btnFogotPass[iter].addEventListener('click', function(evt){
            var btn = evt.target;
            btn.parentElement.querySelector('.hidden').classList.remove('hidden');
            btn.classList.add('hidden');

            // setHeightAuthModal(btn.closest('.popup-reg-header'));
        });
    }

    if (!popupLogin.popup) return;

    if (popupLogin.btnsShow.length) {
        popupLogin.btnsShow.forEach(btn => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                popupLogin.open();
            });
        })
    }

    if (popupClose) {
        popupClose.addEventListener('click', (e) => {
            e.preventDefault();
            const errors = popupLogin.popup.querySelectorAll('.frm-error')

            if(errors.length) {
                errors.forEach(el => el.remove());
            }

            popupLogin.close(window.hidePassword(popupLogin.popup));
        });
    }

    var auth_timeout = 5000;
    var auth_error_timeout = 'Внимание! Время ожидания ответа сервера истекло';
    var auth_error_default = 'Внимание! Произошла ошибка, попробуйте отправить информацию еще раз';

    $('#forgotpass-popup, #forgotpass').on('submit', 'form', function (e) {
        e.preventDefault();

        function bodyMsg(item, msg) {
            var inputError = document.createElement('div');
            inputError.classList.add('frm-error');
            inputError.style.marginTop = '5px';
            inputError.style.textAlign = 'center';
            inputError.textContent = msg;
            item.parentElement.appendChild(inputError);
            setTimeout(function () {
                item.parentElement.removeChild(inputError);
            }, 4000);
        }

        function validateResetEmail(mail, input) {
            if(!mail.length){
                if(!input.parentElement.querySelector('.frm-error')){
                    bodyMsg(input, 'Заполните поле');
                }
                return false;
            }else {
                var re = /\S+@\S+\.\S+/;
                if(!re.test(mail)){
                    if(!input.parentElement.querySelector('.frm-error')){
                        bodyMsg(input, 'Некорректный email');
                    }
                }
                return re.test(mail);
            }
        }

        var mail = this.querySelector('form[name="bform"] div input:not([type="submit"])');

        if(validateResetEmail(mail.value, mail)){
            $.ajax({
                type: "POST",
                url: '/local/ajax/forgotpass.php',
                data: $(this).serializeArray(),
                timeout: auth_timeout,
                error: function (request, error) {
                    if (error == "timeout") {
                        alert(auth_error_timeout);
                    }
                    else {
                        alert(auth_error_default);
                    }
                },
                success: function (data) {
                    let alert = document.querySelector('#popup-alert-pass');

                    if(alert) {
                        alert.remove();
                    }

                    const footer = document.querySelector('footer');

                    footer.insertAdjacentHTML('afterend', data);

                    setTimeout(() => {
                        alert = document.querySelector('#popup-alert-pass');

                        if(!alert) return;

                        const btnsClose = [
                            alert.querySelector('.popup-close'),
                            alert.querySelector('button.btn')
                        ];

                        btnsClose.forEach(btn => {
                            if(!btn) return;

                            btn.addEventListener('click', e => {
                                e.preventDefault();

                                alert.classList.remove('active');
                            })
                        });

                        popupLogin.close(window.hidePassword(popupLogin.popup));
                        alert.classList.add('active');
                    })
                }
            });
        }

        return false;
    });

    $('.sms-resend').on('click', function () {
        $(this).parents('.fogot-form').find('input[name="send_sms_code"]').parent().click();
    });

    $('.resend').on('click', function () {
        $(this).parents('.fogot-form').find('input[name="send_sms_code"]').parent().click();
    });

    const popupSmsAlert = document.querySelector('#popup-alert-sms');

    if(popupSmsAlert) {
        const btnsClose = [
            popupSmsAlert.querySelector('.popup-close'),
            popupSmsAlert.querySelector('button.btn')
        ];

        btnsClose.forEach(btn => {
            if(!btn) return;

            btn.addEventListener('click', e => {
                e.preventDefault();

                popupSmsAlert.classList.remove('active');
            })
        });
    }

    $('input[name="send_sms_code"]').parent().on('click', function (e) {

        e.preventDefault();

        var resetPassForm = $(this).parents('.fogot-form');
        var phone = resetPassForm.find('input[name="PERSONAL_PHONE"]').val();

        resetPassForm.find('input[name="send_sms_code"]').parent().addClass('hidden');
        resetPassForm.find('#resend').parent().addClass('hidden');
        resetPassForm.find('.spinner').parent().removeClass('hidden');
        resetPassForm.find('input[name="sms_code"]').parent().addClass('hidden');
        resetPassForm.find('input[name="check_sms_code"]').parent().addClass('hidden');

        resetPassForm.find('#forgotpass-pass-sms .frm-error').addClass('hidden');

        $.ajax({
            type: 'POST',
            url: '/local/ajax/smsSend.php',
            data: {'phone' : phone, 'action' : 'sendSmsCode'},
            success: function (res) {
                if (res !== 'null') {
                    checkSmsErrorCount = 0;

                    resetPassForm.find('.spinner').parent().addClass('hidden');

                    res = $.parseJSON(res);

                    if (res.id) {
                        resetPassForm.find('input[name="user_id"]').val(res.id);
                    }

                    if (res.success === false && !res.wait) {
                        if(popupSmsAlert) {
                            const subtitile = document.querySelector('.popup-subtitle');

                            subtitile.innerHTML = res.message;
                            popupSmsAlert.classList.add('active');
                        }
                      
                        // $('#message-dialog').on('click', function (e) {
                        //     e.preventDefault();
                        //     $('#popup-sms-alert').dialog('close');
                        //     setTimeout(function () {
                        //         $('.btn-popup-message').click();
                        //     }, 500);
                        // });

                        resetPassForm.find('#forgotpass-pass-sms .frm-error p').html(res.message);
                        resetPassForm.find('#forgotpass-pass-sms .frm-error').removeClass('hidden');
                        setTimeout(function () {
                            resetPassForm.find('#forgotpass-pass-sms .frm-error').addClass('hidden');
                        }, 10000);

                        resetPassForm.find('input[name="send_sms_code"]').parent().removeClass('hidden');
                    }

                    if (res.success === true) {

                        resetPassForm.find('#resend').parent().addClass('hidden');
                        resetPassForm.find('input[name="check_sms_code"]').parent().removeClass('hidden');
                        resetPassForm.find('input[name="sms_code"]').parent().removeClass('hidden');
                        resetPassForm.find('input[name="sms_code"]').val('');
                        resetPassForm.find('input[name="sms_code"]').focus();
                    }

                    if (res.wait !== 'null' && res.count < 9) {
                        var secVal = res.wait;
                        if (secVal > 0) {
                            resetPassForm.find('#resend-timer').parents().removeClass('hidden');
                            resetPassForm.find('input[name="send_sms_code"]').parent().addClass('hidden');
                            resetPassForm.find('input[name="check_sms_code"]').parent().removeClass('hidden');
                            resetPassForm.find('input[name="sms_code"]').parent().removeClass('hidden');
                            resetPassForm.find('input[name="sms_code"]').focus();
                        }

                        if (typeof timer !== 'number') {
                            var timer = setTimeout(function tick() {
                                if (secVal > 1) {
                                    resetPassForm.find('#resend-timer').text(--secVal);
                                    timer = setTimeout(tick, 1000);
                                }
                                else {
                                    resetPassForm.find('#resend-timer').parent().addClass('hidden');
                                    resetPassForm.find('#resend').parent().removeClass('hidden');
                                    resetPassForm.find('input[name="send_sms_code"]').parent().addClass('hidden');
                                }
                            }, 1000);
                        }
                    }
                }
            }
        });
    });

    var checkSmsErrorCount = 0;

    $('input[name="check_sms_code"]').parent().on('click', function (e) {

        e.preventDefault();

        var resetPassForm = $(this).parents('.fogot-form');

        var smsCode = resetPassForm.find('input[name="sms_code"]').val();
        var id = resetPassForm.find('input[name="user_id"]').val();
        var phone = resetPassForm.find('input[name="PERSONAL_PHONE"]').val();

        resetPassForm.find('input[name="check_sms_code"]').prop('disabled', true);
        resetPassForm.find('#forgotpass-pass-sms .frm-error').addClass('hidden');
        // resetPassForm.find('.spinner').parent().removeClass('hidden');

        $.ajax({
            type: 'POST',
            url: '/local/ajax/smsSend.php',
            data: { 'action': 'checkSmsCode', 'id': id, 'phone': phone, 'smsCode': smsCode },
            success: function (res) {

                resetPassForm.find('input[name="check_sms_code"]').prop('disabled', false);

                if (res !== 'null') {

                    res = $.parseJSON(res);

                    if (res.success === false) {
                        checkSmsErrorCount++;

                        if (checkSmsErrorCount < 5) {

                            if(popupSmsAlert) {
                                const subtitle = popupSmsAlert.querySelector('.popup-subtitle');

                                subtitle.innerHTML = res.message;
                                popupSmsAlert.classList.add('active');
                            }

                            resetPassForm.find('#forgotpass-pass-sms .frm-error p').html(res.message);
                            resetPassForm.find('#forgotpass-pass-sms .frm-error').removeClass('hidden');

                            setTimeout(function () {
                                resetPassForm.find('#forgotpass-pass-sms .frm-error').addClass('hidden');
                            } , 10000);
                        }
                        else {
                            resetPassForm.find('input[name="send_sms_code"]').parent().removeClass('hidden');
                            resetPassForm.find('input[name="sms_code"]').parent().addClass('hidden');
                            resetPassForm.find('input[name="check_sms_code"]').parent().addClass('hidden');
                            resetPassForm.find('#resend-timer').parent().addClass('hidden');
                            resetPassForm.find('#resend').parent().addClass('hidden');

                            if(popupSmsAlert) {
                                const subtitle = popupSmsAlert.querySelector('.popup-subtitle');

                                subtitle.innerHTML = 'Превышено допустимое количество ввода неверного кода подтверждения. Получите новый код и попробуйте снова';
                                popupSmsAlert.classList.add('active');
                            }

                            resetPassForm.find('#forgotpass-pass-sms .frm-error p').html('Превышено допустимое количество ввода неверного кода подтверждения. Получите новый код и попробуйте снова');
                            resetPassForm.find('#forgotpass-pass-sms .frm-error').removeClass('hidden');
                            setTimeout(function () {
                                resetPassForm.find('#forgotpass-pass-sms .frm-error').addClass('hidden');
                            } , 10000);
                        }
                    }

                    // backurl = $.urlParam('backurl');

                    if (res.success === true) {
                        // if (backurl !== null) {
                        //     window.location.href = backurl;
                        // } else {
                        //     window.location.href = '/personal/private/?sms_auth=true';
                        // }
                        window.location.href = '/personal/private/?sms_auth=true';
                    }
                }

                if(res === 'null' && !resetPassForm.find('input[name="sms_code"]').val().length) {
                    if(popupSmsAlert) {
                        const subtitle = popupSmsAlert.querySelector('.popup-subtitle');

                        subtitle.innerHTML = 'Недопустимое количество символов кода подтверждения. Получите код и попробуйте снова';
                        popupSmsAlert.classList.add('active');
                    }
                }
            }
        });

    });

    // change tabs
    const forgotpassTabs = $(".forgotpass-pass-tabs-ul li");

    forgotpassTabs.on('click', function () {
        let _this = $(this);
        let blockId = _this.data('div');
        const form = _this.parents('form');
        const formTabs = form.find('.forgotpass-pass-tabs-ul li')

        if(_this.hasClass("active")) return;

        formTabs.each(function (id, tab) {
            let tabContentId = $(tab).data('div');

            clearFields(tabContentId);

            $(tab).removeClass("active");
            form.find(tabContentId).hide();
        });

        form.find(blockId).show();
        _this.addClass("active");

        function clearFields(tabContentId) {
            let inputs = form.find(tabContentId +' input:not([type="submit"])');

            inputs.each(function(id, field) {
                $(field).val('');
            })
        }
    });

    initLoginServices();
}

export function initPopupLoginSuccess() {
    console.log('init popup_login_success.js');
    const popupClose = document.querySelector('.popup-login-success__close');

    if (!popupLoginSuccess.popup) return;

    if (popupClose) {
        popupClose.addEventListener('click', (e) => {
            e.preventDefault();
            popupLoginSuccess.close(function() {
                location.reload();
            });
        });
    }
}

function initLoginServices() {
    const arrBtnsLogin = [
        {
            btn: document.querySelector('#login_tinkoff a'),
            provider: 'tinkoffId'
        },{
            btn: document.querySelector('#login_alpha a'),
            provider: 'alfaId'
        }
    ]
    let debounce;

    arrBtnsLogin.forEach(obj => {
        const { btn, provider } = obj;

        if(!btn) return;

        btn.addEventListener('click', e => {
            e.preventDefault();

            clearTimeout(debounce);

            debounce = setTimeout(() => {
                const ajax = $.ajax({
                    type: 'POST',
                    url: `/local/ajax/checkExtAuth.php?provider=${provider}`,
                    processData: false,
                    contentType: false,
                });
    
                ajax.done(function(res) {
                    console.log(res);

                    if(!res) return;

                    res = JSON.parse(res);

                    switch(res.result) {
                        case 'OK':
                            location.href = res.link
                            break;
                        case 'ERROR':
                            const wrap = btn.closest('.popup-login__btn-auth');
                            wrap.classList.add('error');
                            wrap.innerHTML = 'Авторизация недоступна';
                            break; 
                    }
                })
            }, 300)
        })
    })
}