let timeoutTooltip;

export default function adTooltip(element) {
    if (element.classList.contains('ad-tooltip--init')) return;
    createTooltip(element);

    function createTooltip(element) {
        const erid = element.getAttribute('data-erid');
        let eridText = '';
        const mark = element.getAttribute('data-mark');
        if (erid === undefined || erid === null || erid === '' || mark === undefined || mark === '1') return;
        if (erid !== undefined && erid !== null && erid !== '') {
            eridText = `, erid:${erid}`;
        }
        const tooltipHtml = `
        <div class="ad-tooltip">
            <div class="ad-tooltip__header">
                <span class="ad-tooltip__header-title">Реклама</span>
                <img src="/local/templates/main/img/icon-ad.svg" alt="" class="ad-tooltip__header-icon">
            </div>
            <div class="ad-tooltip__body ad-tooltip__body--top-right">
                <span class="ad-tooltip__body-text">Рекламодатель ООО "Делай мебель", ОГРН: 1157746754956${eridText}</span>
            </div>
        </div>
        `;
        element.insertAdjacentHTML('beforeend', tooltipHtml);
        setTooltipTimeout(element.querySelector('.ad-tooltip'));
        element.classList.add('ad-tooltip--init');
    }
    
    function setTooltipTimeout(tooltipElement) {
        tooltipElement.addEventListener('mouseenter', function() {
            clearTimeoutTooltip()
            tooltipElement.querySelector('.ad-tooltip .ad-tooltip__body').classList.add('ad-tooltip__body--active');
        })
        tooltipElement.addEventListener('mouseleave', function() {
            timeoutTooltip = setTimeout(() => {
                tooltipElement.querySelector('.ad-tooltip__body').classList.remove('ad-tooltip__body--active');
            }, 300)
        })
    }
    
    function clearTimeoutTooltip() {
        clearTimeout(timeoutTooltip);
    }
}