import Popup from './popup_fn';

export default function initPopupGuide() {
    console.log('init initPopupGuide.js');

    window.popupGuide = new Popup('#popup-guide', '.popup-guide__link');
    const popup = document.querySelector('#popup-guide');
    if (popup === null) return;
    const showButton = document.querySelector('.popup-guide__link');

    if (showButton !== null) {
        showButton.addEventListener('click', function(e) {
            e.preventDefault();
            window.popupGuide.open();
        })
    }

    const popupClose = popup.querySelectorAll('.popup-guide__close, .popup-close');
    if (popupClose.length !== 0) {
        popupClose.forEach(button => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                window.popupGuide.close();
            })
        })
    }
}