export default function changeVersionSite() {
    const btnsChangeV = document.querySelectorAll('.old-site .inner-wrap a');
    const btnsCloseChangeV = document.querySelectorAll('.old-site__close');

    if(btnsChangeV.length) {
        btnsChangeV.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();

                const ajax = $.ajax({
                    type: 'POST',
                    data: {
                        'siteType': 'pda'
                    },
                    url: '/local/templates/mobile/ajax/switch_type.php'
                });
    
                ajax.done(function(res) {
                    if (typeof yaCounter50245704 !== 'undefined') {
                        yaCounter50245704.reachGoal('MOBILE_VERSION');
                    }
    
                    window.location.reload();
                })
            })
        })
    }

    if(btnsCloseChangeV.length) {
        btnsCloseChangeV.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();
    
                let date = new Date(new Date().setMonth(new Date().getMonth() + 3)).toUTCString();
    
                document.cookie = 'mobile_version_modal=1; expires=' + date + '; path=/';
                $(btn).parents('.old-site').slideUp();
            })
        })
    }
}