import { checkProductAvailable, createSpinner } from '../../common/functions';
import { initPopupComplect } from '../popups/popup_complect';
import initSliderTabs from './slider_tabs';
import updateProductPrices from './updateProductPrices';

export default function addToCartItem() {
    console.log('init add_to_cart.js');

    let data;
    let _self;

    $(document).delegate('.frm-counter + .btn, .btn-add-cart', 'click', addToCart);

    function addToCart(e) {
        _self = $(e.target);

        if(e.target.closest('.item-catalog')) return;

        if(_self.hasClass('btn-second')) {
            if(_self.data('href')) {
                e.preventDefault();
                location.href = _self.data('href');
            }

            return;
        } 

        e.preventDefault();

        _self.html(createSpinner());

        data = _self.data();

        var counter = _self.prev();
        var btnHref = _self.data('href') 
            ? _self.data('href').trim()
            : _self.attr('href').trim();

        if(!btnHref) return;

        var urlCart = btnHref + "index.php";

        data.cnt = 1;

        if (counter.hasClass('frm-counter')) {
            data.cnt = counter.find('input').val();
        }

        let ajax = $.ajax({
            type: "POST",
            data: data,
            url: urlCart,
            dataType: "html"
        });

        ajax.done(getBasketSum);
    }

    function getBasketSum(res) {
        if(!res) return;

        res = JSON.parse(res);

        $('.btn-action-cart .btn-action-cart__price').html(res.sum);

        let data_href = $('.btn-action-cart').data('href');

        $('.btn-action-cart').attr('href', data_href);

        $('.btn').each(function (i, e) {
            var e = $(e);
            if (e.data('id') == data.id) {
                e.addClass('btn-second');
                e.text(e.data('text'));
            }
        });

        initPopupComplect(_self[0], res);
      
        let ajax = $.ajax({
            type: "POST",
            url: '/local/ajax/basket.php',
            dataType: "html"
        });

        ajax.done(getBasketCount);
    }

    function getBasketCount(res) {
        if(!res) return;

        $('.btn-action-cart .btn-action-cart__qty').html(res);
        $('.btn-action-cart .btn-action-cart__qty').addClass('active');
        $('.basket-val').html(res);
        if(res > 0) $('.basket-val').css('display','block');

        if(_self.parents('.cat-hidden-size').length) {
            _self.siblings().hide(function() {
                _self.css('width','100%');
            })
        }

        // set qty detail page
        const deatilPageWrapper = document.querySelector('.product-box[data-detail]');
        const deatilPageWrapperMobile = document.querySelector('.product-box-mobile[data-detail]');

        if(deatilPageWrapper || deatilPageWrapperMobile) {
            const inputQty = _self.parent().find('.frm-counter input');

            if(_self.hasClass('tbl-products__btn')) {
                const inputsQty = _self.parents('.item-product').find('.frm-counter input');

                inputsQty.val(inputQty.val());
                checkProductAvailable(inputQty.val(), _self);
            } else {
                const cardContentWrapper = _self.parents('.product__variant-list');

                if(cardContentWrapper.length) {
                    let qty = _self[0].dataset.quantity 
                        ? parseInt(_self[0].dataset.quantity)
                        : 1;

                    checkProductAvailable(qty, _self);
                } else {
                    $('.frm-counter input').val(inputQty.val());
                    checkProductAvailable(inputQty.val());
                }
            }
        }

        if($('.product-lunchmeat').length) {
            $('.product-lunchmeat').show();
        }

        if(window.index_1) {
            const totalSum = document.querySelector('.btn-action-cart__price');
            const wrapperForHandler = document.querySelector('.page__content');

            if(totalSum && wrapperForHandler) {
                const basket_1 = new index_1.default(wrapperForHandler, totalSum);
                basket_1.update();
            }
        }
    }
}