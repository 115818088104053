export default function initPopupPhone() {
    const btnShowPhonePopup = document.querySelector('.phone-block__ico');
    const popupPhone = document.querySelector('.popup-phone');

    if (!btnShowPhonePopup || !popupPhone) return;

    btnShowPhonePopup.addEventListener('click', (e) => {
        e.preventDefault();
        btnShowPhonePopup.classList.toggle('active');
        popupPhone.classList.toggle('active');
    });

    document.addEventListener('click', e => {
        let btn = e.target.classList.contains('phone-block__ico')
            ? e.target
            : e.target.closest('.phone-block__ico');
        let popup = e.target.classList.contains('popup-phone')
            ? e.target
            : e.target.closest('.popup-phone');

        if(btn || popup) return;
            
        btnShowPhonePopup.classList.remove('active');
        popupPhone.classList.remove('active');
    });
}